/* eslint-disable func-style */
import { DialogService } from '@ms/core/components/dialog/service/dialog.service';
import { CalendarEvent } from 'app/layout/components/content/calendar/calendar-event.model';
import { IMeeting, Subscriber, SubscriberType } from 'app/main/shared/model/meetings-model.model';
import { environment } from 'environments/environment';
import { CourseAssignment } from '../course-assignment/course-assignment.model';
import { SyllabusItem } from '../school-course/syllabus/syllabus.model';
import { ChatSubscriptionSummary } from '../service/chat-subscription.service';
import { ICourse } from './model/course.model';
export function emptyFunction() {}

export type StudentViewOption =
    | 'custom'
    | 'document'
    | 'parent'
    | 'address'
    | 'courses'
    | 'group'
    | 'discount'
    | 'attachment'
    | 'enrollment'
    | 'medical'
    | 'note'
    | 'achievement'
    | 'sibling'
    | '*';

export async function openSendMessageDialog(
    users: string[],
    fullName: string,
    subject: string = '',
    message: string = '',
    closeEvent: any = () => {}
) {
    const comp = await import('app/main/user-message/send-message-dialog/send-message-dialog.component').then(
        m => m.SendMessageDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1000px',
            data: {
                toUsers: users,
                subscriber: {
                    subscriberType: SubscriberType.USER,
                    subscriberName: fullName,
                },
                subject,
                message,
            },
        },
        () => {
            if (closeEvent) {
                closeEvent();
            }
        }
    );
}

export async function openChats(
    entityId: string,
    description: string,
    isModerator: boolean,
    userId: string,
    chatList: ChatSubscriptionSummary[],
    selectedChat: ChatSubscriptionSummary,
    openVideoConference = false,
    closeEvent: any = () => {}
) {
    const comp = await import(
        'app/layout/components/content/assignment-panel-student/discussion-dialog/discussion-dialog.component'
    ).then(m => m.DiscussionDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            width: '700px',
            height: '800px',
            data: {
                entityId,
                name: description ?? 'Chat',
                editable: true,
                isModerator,
                userId,
                chatList,
                selectedChat,
                refreshList: false,
                openVideoConference,
            },
        },
        () => {
            if (closeEvent) {
                closeEvent();
            }
        }
    );
}

export async function openStudentView(
    studentId: string,
    options: StudentViewOption[] = ['*'],
    closeEvent: any = () => {}
) {
    const comp = await import('app/main/students/student-list/view/student-view.component').then(
        m => m.StudentViewComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            width: '1200px',
            data: {
                studentId,
                options,
            },
        },
        () => {
            if (closeEvent) {
                closeEvent();
            }
        }
    );
}

export async function openSubmissionDialog(
    studentId: string,
    groupId: string,
    studentOrGroupName: string,
    assignment: CourseAssignment,
    isStatusNoSubmission: boolean,
    isGradingLockedForDueDatePassed: boolean,
    termGradeDueDate: Date,
    closeEvent: any = () => {}
) {
    const comp = await import('../course-assignment/review-submissions/dialog/review-submission-dialog.component').then(
        m => m.ReviewSubmissionDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1100px',
            height: '750px',
            data: {
                studentId,
                groupId,
                studentOrGroupName,
                assignment,
                isStatusNoSubmission,
                isGradingLockedForDueDatePassed,
                termGradeDueDate,
            },
        },
        result => {
            if (result == true && closeEvent) {
                closeEvent();
            }
        }
    );
}

export async function openCreateCourseEventDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/layout/components/content/course-board/course-event-create-dialog/course-event-create-dialog'
    ).then(m => m.CourseEventCreateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        result => {
            if (result?.action == 'save') {
                closeEvent();
            }
        }
    );
}

export async function openGoogleCalendarDialog(startDate: Date, endDate: Date) {
    const comp = await import(
        '@ms/core/components/google-calendar/google-calendar-dialog/google-calendar-dialog.component'
    ).then(m => m.GoogleCalendarDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '1000px',
        data: {
            dateRange: {
                startDate,
                endDate,
            },
        },
    });
}

export async function openCreateNewCalendarEventDialog(event?: CalendarEvent) {
    const comp = await import(
        'app/layout/components/content/calendar/custom-calendar-event/select-dialog/calendar-event-select-dialog.component'
    ).then(m => m.CalendarEventSelectDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        width: '450px',
        data: {
            event: event ?? new CalendarEvent(),
        },
    });
}

export async function openStartCourseEvents(content: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/details/course-details-dialog.component').then(
        m => m.CourseDetailsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: content.minWidth ?? '1100px',
            data: {
                event: content.event,
                periods: content.periods,
            },
        },
        () => {
            closeEvent();
        }
    );
}

export async function openStartMeetingEvents(content: any) {
    const comp = await import('app/main/education/meetings/details/meetings-details-dialog.component').then(
        m => m.MeetingsDetailsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '600px',
        data: {
            meetingId: content.event.referenceId,
            eventId: content.event.id,
        },
    });
}

export async function openCustomCalendarEvent(content?: any) {
    const comp = await import(
        'app/layout/components/content/calendar/custom-calendar-event/custom-calendar-event.component'
    ).then(m => m.CustomCalendarEventComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '850px',
        data: {
            event: content?.event,
        },
    });
}

export async function openSyllabusContentDialog(
    syllabusItem: SyllabusItem,
    course: ICourse,
    isCourseTeacher: boolean,
    closeEvent: any = () => {}
) {
    const comp = await import(
        'app/main/school-course/syllabus/syllabus-content-dialog/syllabus-content-dialog.component'
    ).then(m => m.SyllabusContentDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            autoFocus: false,
            data: {
                syllabusItem,
                isCourseTeacher,
                course,
            },
        },
        () => {
            closeEvent();
        }
    );
}

export async function openSubscribersDialog(
    type: 'moderators' | 'attendees' | undefined,
    title: string,
    subscriberType: SubscriberType,
    single: boolean = false,
    userId?: string,
    closeEvent: any = () => {}
) {
    const comp = await import('app/main/shared/subscribers/dialog/subscribers-dialog.component').then(
        m => m.SubscribersDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1000px',
            data: {
                type,
                title,
                subscriberType,
                single,
                userId,
            },
        },
        response => {
            if (response?.action == 'complete') {
                closeEvent();
            }
        }
    );
}

export async function openCourseMessageDialog(
    meetingId: string,
    eventId: string,
    isModerator: boolean,
    allSubscribers: Subscriber[],
    selectedSubscribers: Subscriber[]
) {
    const comp = await import('app/main/school-course/details/message/course-message-dialog.component').then(
        m => m.CourseMessageDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        width: '1000px',
        data: {
            meetingId,
            eventId,
            isModerator,
            allSubscribers,
            selectedSubscribers,
        },
    });
}

export async function openLessonPlanDialog(calendarEvent: CalendarEvent, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/details/lesson-plan-dialog/lesson-plan-dialog.component').then(
        m => m.LessonPlanDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1200px',
            height: '800px',
            data: {
                calendarEvent,
            },
        },
        response => closeEvent(response)
    );
}

export async function openViewRecording(meeting: IMeeting, closeEvent: any = () => {}) {
    const comp = await import('app/main/education/meetings/recording/meetings-recording.component').then(
        m => m.MeetingsRecordingComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '100%',
            height: '100%',
            data: {
                meeting,
            },
        },
        response => closeEvent(response)
    );
}

export async function openPdfViewer(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/ms-pdf-viewer/ms-pdf-viewer-dialog.component').then(
        m => m.MsPdfViewerDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseAttendanceDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/details/attendance/course-event-attendance-dialog.component'
    ).then(m => m.CourseEventAttendanceDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1200px',
            height: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openChangeCourseModeratorDialogComponent(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/education/course-moderators/change-course-moderator-dialog/change-course-moderator-dialog.component'
    ).then(m => m.ChangeCourseModeratorDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseModeratorDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/education/course-moderators/dialog/course-moderator-dialog.component').then(
        m => m.CourseModeratorDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttendanceDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/attendance-by-student/dialog/attendance-dialog.component').then(
        m => m.AttendanceDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTranslateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/translation/translation-dialog/translation-dialog.component').then(
        m => m.TranslationDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFileSelectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('@ms/core/components/file-select-dialog/file-select-dialog.component').then(
        m => m.FileSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openMyDriveDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/my-drive-select-dialog/my-drive-select-dialog.component').then(
        m => m.MyDriveSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEmployeeImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employees-upload-dialog/employees-import-dialog.component'
    ).then(m => m.EmployeesImportDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openRegistrationInfoViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/entrance-exam/registration/registration-info-view-dialog.component').then(
        m => m.RegistrationInfoViewDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            minHeight: '95%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExamMailDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/entrance-exam/complete/dialog/send-dialog.component').then(
        m => m.SendExamEmailDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            minHeight: '382px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExamSendMailDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/entrance-exam/send-email/send-email-dialog.component').then(
        m => m.SendEmailDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1030px',
            height: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExamSendSmsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/entrance-exam/send-sms/send-sms-dialog.component').then(
        m => m.SendSmsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1030px',
            height: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentCourseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-list/student-course-info-dialog/student-course-info-dialog.component'
    ).then(m => m.StudentCourseInfoDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEnrollmentViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/enrollments/dialog/enrollment-view-dialog.component'
    ).then(m => m.EnrollmentViewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEnrollViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/dialog-enroll/student-enroll-dialog.component'
    ).then(m => m.StudentEnrollDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentAchivementDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/student-achievements/dialog/student-achievement-dialog.component'
    ).then(m => m.StudentAchievementDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSpecialCodeDialog(data: any) {
    const comp = await import(
        'app/main/students/student-list/student-form/student-special-code/student-special-code-dialog.component'
    ).then(m => m.StudentSpecialCodeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        width: '550px',
        data: data,
    });
}

export async function openSiblingRepresentativeSelectionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/student-representatives/sibling-representative-selection/sibling-representative-select-dialog.component'
    ).then(m => m.SiblingRepresentativeSelectDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentRepresentativeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/student-representatives/dialog/student-representative-dialog.component'
    ).then(m => m.StudentRepresentativeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            minHeight: '505px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserSelectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/user-select/user-select-dialog/user-select-dialog.component').then(
        m => m.UserSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserContractGenerateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/user-contract-generate/dialog/user-contract-generate-dialog.component'
    ).then(m => m.UserContractGenerateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentPhotoDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/upload/student-upload-dialog.component'
    ).then(m => m.StudentUploadDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentDiscountDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/dialog-discount/student-discount-dialog.component'
    ).then(m => m.StudentDiscountDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openWebCamDialog(data: any) {
    const comp = await import('@ms/core/webcam/webcam/webcam-dialog/webcam-dialog.component').then(
        m => m.WebcamDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '650px',
        data: data,
    });
}

export async function openStudentConvertDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/dialog-convert/student-convert-dialog.component'
    ).then(m => m.StudentConvertDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1300px',
            height: '100vh',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCardBackgroundDialog(data: any) {
    const comp = await import('app/main/card-background-select/card-background-select-dialog.component').then(
        m => m.CardBackgroundSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '550px',
        data: data,
    });
}

export async function openStudentScheduleDownloadDialog(data: any) {
    const comp = await import('app/main/student-list/student-schedule/student-schedule-dialog.component').then(
        m => m.StudentScheduleDownloadDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '550px',
        data: data,
    });
}

export async function openStudentGradeImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/student-form/dialog-import-grades/student-import-grades-dialog.component'
    ).then(m => m.StudentImportGradesDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserContractDialog(data: any) {
    const comp = await import(
        'app/main/students/student-list/student-form/user-contract-generate/user-contract-dialog.component'
    ).then(m => m.UserContractDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '550px',
        data: data,
    });
}

export async function openStudentIdGenerateDialog(closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/student-list/dialog-generate-student-id/student-generate-student-id-dialog.component'
    ).then(m => m.StudentGenerateStudentIdDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '600px',
        },
        response => closeEvent(response)
    );
}

export async function openRubricRatingDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grading-rubric/dialog/rating-dialog/rating-dialog.component').then(
        m => m.GradingRubricRatingDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '400px',
            data,
        },
        response => closeEvent(response)
    );
}

export async function openRubriCriteriaDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grading-rubric/dialog//criteria-dialog/criteria-dialog.component').then(
        m => m.GradingRubricCriteriaDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '400px',
            data,
        },
        response => closeEvent(response)
    );
}

export async function openGradingRubricDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grading-rubric/dialog/grading-rubric-dialog.component').then(
        m => m.GradingRubricDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1200px',
            data,
        },
        response => closeEvent(response)
    );
}

export async function openGoogleCalendarCreatorDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        '@ms/core/components/google-calendar/google-calendar-selector/google-calendar-creator.component'
    ).then(m => m.GoogleCalendarCreatorComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data,
        },
        response => closeEvent(response)
    );
}

export async function openMeetingAttachmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/attachment-table/dialog/attachment-table-dialog.component').then(
        m => m.AttachmentTableDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuestionResultDialog(data: any) {
    const comp = await import(
        'app/main/school-course/syllabus/form/content/video/video-questions-dialog/question-result-dialog/question-result-dialog.component'
    ).then(m => m.QuestionResutDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(comp, {
        minWidth: '1000px',
        data,
    });
}

export async function openQuestionBankDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/assignment-questions-dialog/question-bank-questions-dialog/question-bank-questions-dialog.component'
    ).then(m => m.QuestionBankQuestionsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '70vh',
            minHeight: '70vh',
            width: '70%',
            height: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openVideoQuestionBankQuestionsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/form/content/video/video-questions-dialog/question-bank-questions-dialog/question-bank-questions-dialog.component'
    ).then(m => m.VideoQuestionBankQuestionsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '70vh',
            minHeight: '70vh',
            width: '70%',
            height: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuestionViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/question-bank/questions/form/question-form/question/question-view-dialog.component'
    ).then(m => m.QuestionViewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '60vw',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSyllabusContentVideoQuestionsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/form/content/video/video-questions-dialog/video-questions-dialog.component'
    ).then(m => m.SyllabusContentVideoQuestionsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '90vh',
            minHeight: '90vh',
            width: '90%',
            height: '90',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentVideoPositionsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/student-video-positions/student-video-positions-dialog.component'
    ).then(m => m.StudentVideoPositionsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openImportFromSyllabusDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/import-from-syllabus-tree-dialog/import-from-syllabus-tree-dialog.component'
    ).then(m => m.ImportFromSyllabusTreeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentSubmissionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/layout/components/content/assignment-panel-student/assignment-submission-dialog/assignment-submission-dialog.component'
    ).then(m => m.AssignmentSubmissionDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            width: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentNewAttemptRequestDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/layout/components/content/assignment-panel-student/assignment-new-attempt-request-dialog/assignment-new-attempt-request-dialog.component'
    ).then(m => m.AssignmentNewAttemptRequestDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            width: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuizSubmissionReviewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/review-submissions/quiz-review/quiz-submission-review-dialog.component'
    ).then(m => m.QuizSubmissionReviewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeExcuseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/grade-excuse-dialog/grade-excuse-dialog.component').then(
        m => m.GradeExcuseDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExtraAttemptDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/review-submissions/extra-attempt-dialog/extra-attempt-dialog.component'
    ).then(m => m.ExtraAttemptDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '450px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openLessonStepDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/details/lesson-plan-dialog/lesson-step-dialog/lesson-step-dialog.component'
    ).then(m => m.LessonStepDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttendanceChangeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/details/attendance/dialog/attendance-change-dialog.component'
    ).then(m => m.AttendanceChangeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFeeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/academic-period-tree/form/fee-dialog/fee-dialog.component').then(
        m => m.FeeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openItemDetailViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/item-transactions/item-transaction-form/item-detail-view-dialog/item-detail-view-dialog.component'
    ).then(m => m.ItemDetailViewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openItemDetailNewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/item-transactions/item-transaction-form/item-detail-new-dialog/item-detail-new-dialog.component'
    ).then(m => m.ItemDetailNewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openItemStatusUpdateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/item-transactions/item-status-update-dialog/item-status-update-dialog.component'
    ).then(m => m.ItemStatusUpdateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityItemsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/entity-items/items-dialog/items-dialog.component').then(
        m => m.EntityItemsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSchoolDepartmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-department/form/school-department-dialog.component').then(
        m => m.SchoolDepartmentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeLevelFormDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grade-levels/form/grade-level-dialog.component').then(
        m => m.GradeLevelFormComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSchoolLocationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-location/form/school-location-dialog.component').then(
        m => m.SchoolLocationDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSchoolClassDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-class/form/school-class-dialog.component').then(
        m => m.SchoolClassDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openMeetingServersDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/shared/bbb-server-settings/meeting-servers/meeting-servers-dialog.component'
    ).then(m => m.MeetingServersDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSubjectCategoryDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/subject-categories/dialog/subject-category-dialog.component').then(
        m => m.SubjectCategoryDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentProgramSetupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-program/setup/dialog/student-program-setup-dialog.component').then(
        m => m.StudentProgramSetupDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentProgramEnrollmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-program/enrollment/student-program-enrollment-dialog.component').then(
        m => m.StudentProgramEnrollmentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1100px',
            data: data,
        },
        response => closeEvent(response)
    );
}


export async function openStudentAlertDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-list/alert/student-alert-dialog.component').then(
        m => m.StudentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFeeTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/fee-type/fee-type-dialog/fee-type-dialog.component').then(
        m => m.FeeTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDiscountDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/discount/dialog/discount-dialog.component').then(
        m => m.DiscountDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCustomParameterDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/custom-parameters/dialog/custom-parameter-dialog.component').then(
        m => m.CustomParameterDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSingleCustomParameterDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/custom-parameters/single-category-dialog/single-category-custom-parameter-dialog.component'
    ).then(m => m.SingleCategoryCustomParameterDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            height: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttendanceCodeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/attendance-codes/dialog/attendance-code-dialog.component').then(
        m => m.AttendanceCodeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDocumentTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/document-types/dialog/document-type-dialog.component').then(
        m => m.DocumentTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTextToSpeechSettingsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/schools/school-list/school-form/text-to-speech-settings/dialog/text-to-speech-settings-dialog.component'
    ).then(m => m.TextToSpeechSettingsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableItemSelection(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/rooms/select/selection.component').then(
        m => m.TimetableItemSelectionComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableCourseGroupsSelectionComponent(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/courses/import/groups/groups.component').then(
        m => m.TimetableCourseGroupsSelectionComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSubjectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/subjects/dialog/subject-dialog.component').then(
        m => m.SubjectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSubjectsImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/subjects/import/subjects-import-dialog.component').then(
        m => m.SubjectsImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExternalRecourceView(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/form/external-view-resource/external-resource-view.component'
    ).then(m => m.ExternalRecourceViewComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openInvolvementItemDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/involvement-item/dialog/involvement-item-dialog.component').then(
        m => m.InvolvementItemDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentEntityDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/incident/incident-list/form/incident-entity-dialog/incident-entity-dialog.component'
    ).then(m => m.IncidentEntityDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentLocationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/incident/location/dialog/incident-location-dialog.component').then(
        m => m.IncidentLocationDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentPointDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/incident/incident-list/form/incident-point-dialog/incident-point-dialog.component'
    ).then(m => m.IncidentPointDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentBehaviorDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/incident/incident-list/form/incident-behavior/incident-behavior-dialog.component'
    ).then(m => m.IncidentBehaviorDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentActionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/incident/incident-list/form/incident-action/incident-action-dialog.component'
    ).then(m => m.IncidentActionDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentObjectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/incident/incident-list/form/incident-object/incident-object-dialog.component'
    ).then(m => m.IncidentObjectDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentsImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-list/students-upload-dialog/students-import-dialog.component').then(
        m => m.StudentsImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCollectiveCourseAssignDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-list/collective-course-assign-dialog/collective-course-assign-dialog.component'
    ).then(m => m.CollectiveCourseAssignDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSelectEditRepresentativeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/students/representative-account/dialog/select-edit-representative-dialog.component'
    ).then(m => m.SelectEditRepresentativeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSalaryConstantDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/salary-constants/dialog/salary-constant-dialog.component').then(
        m => m.SalaryConstantDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openItemDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/item/item-dialog/item-dialog.component').then(m => m.ItemDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableGroups(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/classes/dialog/groups/groups.component').then(
        m => m.TimetableGroupsComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableClassDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/classes/dialog/class-dialog.component').then(
        m => m.TimetableClassDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            maxHeight: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableRoomDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/rooms/dialog/room-dialog.component').then(
        m => m.TimetableRoomDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableTeacherDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/teachers/dialog/teacher-dialog.component').then(
        m => m.TimetableTeacherDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableCourseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/courses/dialog/course-dialog.component').then(
        m => m.TimetableCourseDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableProjectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/projects/dialog/project-dialog.component').then(
        m => m.TimetableProjectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '860px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openProjectCopyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/projects/copy/project-copy-dialog.component').then(
        m => m.ProjectCopyDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableTrialDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/trials/dialog/trial-dialog.component').then(
        m => m.TimetableTrialDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableTrialCopyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/trials/copy/trial-copy-dialog.component').then(
        m => m.TimetableTrialCopyDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEmployeeAttestationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-attestations/dialog/employee-attestation-dialog.component'
    ).then(m => m.EmployeeAttestationDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCertificateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-certificates/dialog/certificate-dialog.component'
    ).then(m => m.CertificateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEmployeeContractDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-contracts/dialog/contract-dialog.component'
    ).then(m => m.ContractDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDismissalArticleDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/dismissal-article/dialog/dismissal-article-dialog.component').then(
        m => m.DismissalArticleDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDismissalDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-dismiss/dialog/dismiss-dialog.component'
    ).then(m => m.DismissDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEducationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-educations/dialog/education-dialog.component'
    ).then(m => m.EducationDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExperienceDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/employee-experience/dialog/experience-dialog.component'
    ).then(m => m.ExperienceDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEmployeeUploadDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/employees/employee-list/employee-form/upload/employee-upload-dialog.component'
    ).then(m => m.EmployeeUploadDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openExamCopyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/exams/exam-list/exam-copy-dialog/exam-copy-dialog.component').then(
        m => m.ExamCopyDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityFieldSettingsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/shared/entity-field/settings/dialog/entity-field-settings-dialog.component'
    ).then(m => m.EntityFieldSettingsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityFieldSelectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/entity-field/settings/field-select/field-select-dialog.component').then(
        m => m.EntityFieldSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityFieldSettingsGroupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/shared/entity-field/settings/group/entity-field-settings-group-dialog.component'
    ).then(m => m.EntityFieldSettingsGroupDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFieldDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/fields-table/dialog/field-dialog.component').then(
        m => m.FieldDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCronTriggerDialogComponent(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        '@ms/core/components/cron-trigger/cron-trigger-dialog/cron-trigger-dialog.component'
    ).then(m => m.CronTriggerDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCountryDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/countries/dialog/country-dialog.component').then(m => m.CountryDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityFieldDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/shared/entity-field/fields/dialog/entity-field-dialog.component').then(
        m => m.EntityFieldDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCustomFieldGroupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/custom-field-group/dialog/custom-field-group-dialog.component').then(
        m => m.CustomFieldGroupDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '450px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCityDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/cities/dialog/city-dialog.component').then(m => m.CityDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/states/dialog/state-dialog.component').then(m => m.StateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStateReportFieldDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/state-report/dialog/state-report-field-dialog.component').then(
        m => m.StateReportFieldDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCitizenshipDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/citizenships/dialog/citizenship-dialog.component').then(
        m => m.CitizenshipDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openBBBServerDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/bbb-server/dialog/bbb-server-dialog.component').then(
        m => m.BBBServerDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPositionCategoryDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/position-category/dialog/position-category-dialog.component').then(
        m => m.PositionCategoryDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSchoolCourseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/dialog/school-course-dialog.component').then(
        m => m.SchoolCourseDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseStudentsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/dialog/groups/dialog/students/course-students-dialog.component'
    ).then(m => m.CourseStudentsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGroupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/dialog/groups/dialog/group-dialog.component').then(
        m => m.GroupDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSyllabusViewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/syllabus-view-dialog/syllabus-view-dialog.component'
    ).then(m => m.SyllabusViewDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1200px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseCopyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/dialog/course-copy/course-copy.component').then(
        m => m.CourseCopyComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            height: '70%',
            width: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseImageUploadDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/dialog/upload/course-image-upload-dialog.component').then(
        m => m.CourseImageUploadDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSyllabusImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/school-course/syllabus-import-dialog/syllabus-import-dialog.component').then(
        m => m.SyllabusImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseAssignmentGroupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/assign-list/assignment-group-dialog/assignment-group-dialog.component'
    ).then(m => m.CourseAssignmentGroupDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '100%',
            height: '100%',
            minWidth: '100vw',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGroupMembersDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/assignment-questions-dialog/group-members-dialog/group-members-dialog.component'
    ).then(m => m.GroupMembersDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuestionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/question-bank/questions/form/question-dialog.component').then(
        m => m.QuestionDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            height: '70%',
            width: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuizSettingsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/assignment-questions-dialog/quiz-settings-dialog/quiz-settings-dialog.component'
    ).then(m => m.QuizSettingsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            height: 'fit-content',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentQuestionsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/assignment-questions-dialog/assignment-questions-dialog.component'
    ).then(m => m.AssignmentQuestionsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '90vh',
            minHeight: '90vh',
            width: '90%',
            height: '90',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPreviewQuizDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/course-assignment/form/preview-quiz-dialog/preview-quiz-dialog.component').then(
        m => m.PreviewQuizDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentCopyArchiveDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-assignment/form/copy-archive-dialog copy/assignment-copy-archive-dialog.component'
    ).then(m => m.AssignmentCopyArchiveDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentQuickCreateFormDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/course-assignment/form/quick-create-dialog/quick-create-dialog.component').then(
        m => m.AssignmentQuickCreateFormDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '860px',
            height: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAdjustWithGradebookDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/adjust-with-gradebook-dialog/adjust-with-gradebook-dialog.component'
    ).then(m => m.AdjustWithGradebookDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openAdjustWithTimetableDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/syllabus/adjust-with-timetable-dialog/adjust-with-timetable-dialog.component'
    ).then(m => m.AdjustWithTimetableDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseLessonDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/school-course/course-lessons/course-lesson-dialog/course-lesson-dialog.component'
    ).then(m => m.CourseLessonDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeRangeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grade-scheme/dialog/grade-range-dialog/grade-range-dialog.component').then(
        m => m.GradeRangeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeSchemeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grade-scheme/dialog/grade-scheme-dialog.component').then(
        m => m.GradeSchemeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '950px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeExcuseTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/grade-excuse-type/dialog/grade-excuse-type-dialog.component').then(
        m => m.GradeExcuseTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openNationalityDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/nationality/dialog/nationality-dialog.component').then(
        m => m.NationalityDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openParentExcuseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/attendance-excuse/parent-excuse-dialog/parent-excuse-dialog.component').then(
        m => m.ParentExcuseDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttendanceExcuseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/attendance-excuse/dialog/attendance-excuse-dialog.component').then(
        m => m.AttendanceExcuseDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttendanceExcuseRequestDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/attendance-excuse-requests/dialog/attendance-excuse-requests-dialog.component'
    ).then(m => m.AttendanceExcuseRequestDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFeeDiscountEditDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-fees/student-fees-form/student-fee-discount-edit-dialog/student-fee-discount-edit-dialog-component'
    ).then(m => m.FeeDiscountEditDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPaymentInstallmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-payment/payment-online/installment-dialog/payment-installment-dialog.component'
    ).then(m => m.PaymentInstallmentDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '40vh',
            minHeight: '40vh',
            height: '60%',
            width: '50%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentFeeTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-fees/student-fees-form/fee-type-dialog/fee-type-dialog.component').then(
        m => m.StudentFeeTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFeeEditDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-fees/student-fees-form/student-fee-edit-dialog/fee-edit-dialog.component'
    ).then(m => m.FeeEditDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentPaymentRefundDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-fees/student-fees-form/student-refund-dialog/student-refund-dialog.component'
    ).then(m => m.StudentPaymentRefundDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openFeeDetailDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-fees/student-fees-form/student-fee-detail-dialog/fee-detail-dialog.component'
    ).then(m => m.FeeDetailDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCancelFeeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-fees/student-fees-form/cancel-fee-dialog/cancel-fee-dialog.component'
    ).then(m => m.CancelFeeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '1000px',
            minHeight: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentPaymentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-payment/student-payment-dialog/payment-dialog.component').then(
        m => m.StudentPaymentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentPaymentDetailDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/student-payment/form/student-payment-detail-dialog/payment-detail-dialog.component'
    ).then(m => m.StudentPaymentDetailDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openBankAccountDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/bank-accounts/dialog/bank-account-dialog.component').then(
        m => m.BankAccountDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openSchoolSelectDialog(closeEvent: any = () => {}) {
    const comp = await import('app/main/schools/select-dialog/school-select-dialog.component').then(
        m => m.SchoolSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
        },
        response => closeEvent(response)
    );
}

export async function openPasswordDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/layout/components/password-change/password-dialog.component').then(
        m => m.PasswordDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            disableClose: true,
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openProfileDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/layout/components/profile/profile-dialog.component').then(
        m => m.ProfileDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTenantSelectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/layout/components/toolbar/tenant-select/tenant-select-dialog.component').then(
        m => m.TenantSelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openParentStudentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/layout/components/parent-student/parent-student-dialog.component').then(
        m => m.ParentStudentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserUploadDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/layout/components/profile/upload/user-upload-dialog.component').then(
        m => m.UserUploadDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimePeriodDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-periods/dialog/time-period-dialog.component').then(
        m => m.TimePeriodDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1100px',
            minHeight: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEntityAutocompleteDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/item-transactions/entity-autocomplete-dialog/entity-autocomplete-dialog.component'
    ).then(m => m.EntityAutocompleteDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAttestationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/attestation/dialog/attestation-dialog.component').then(
        m => m.AttestationDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openItemCategoryDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/item-category/item-category-dialog/item-category-dialog.component').then(
        m => m.ItemCategoryDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEmployeePositionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/employee-position/dialog/employee-position-dialog.component').then(
        m => m.EmployeePositionDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentPaymentReportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-payment-report/dialog/student-payment-report-dialog.component').then(
        m => m.StudentPaymentReportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDailyAttendanceDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/daily-attendance/dialog/daily-attendance-dialog.component').then(
        m => m.DailyAttendanceDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openReminderDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/daily-attendance/reminder-dialog/reminder-dialog.component').then(
        m => m.ReminderDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openRecordingMembersDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/education/recordings/dialog/recording-members-dialog.component').then(
        m => m.RecordingMembersDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openRecordingMembersListDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/education/recordings/dialog/list/recording-members-list-dialog.component').then(
        m => m.RecordingMembersListDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentGroupDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/student-groups/dialog/student-group-dialog.component').then(
        m => m.StudentGroupDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openTransferHolidayDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-table/transfer-holiday-dialog/transfer-holiday-dialog.component').then(
        m => m.TransferHolidayDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCourseEventDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-table/course-event-dialog/course-event-dialog.component').then(
        m => m.CourseEventDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimetableImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-table/import-dialog/timetable-import-dialog.component').then(
        m => m.TimetableImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTimeTableDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-table/dialog/time-table-dialog.component').then(
        m => m.TimeTableDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openConfirmationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/time-table/time-table-form/confirmation-dialog/confirmation-dialog.component'
    ).then(m => m.ConfirmationDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openLocationChangeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/time-table/time-table-form/location-change-dialog/location-change-dialog.component'
    ).then(m => m.LocationChangeDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '550px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openReplyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/user-message/reply-form/dialog/reply-dialog.component').then(
        m => m.ReplyDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserMessageDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/user-message/message-dialog/user-message-dialog.component').then(
        m => m.UserMessageDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradeNoteDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-term-grade/term-grade/grade-note-dialog/grade-note-dialog.component'
    ).then(m => m.GradeNoteDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradebookLogDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/gradebook/course-based/gradebook-log-dialog/gradebook-log-dialog.component'
    ).then(m => m.GradebookLogDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGradingMapDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/gradebook/grading-map-dialog/grading-map-dialog.component').then(
        m => m.GradingMapDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openCustomAssignmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/gradebook/course-based/custom-assignment/custom-assignment-dialog.component'
    ).then(m => m.CustomAssignmentDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openConfirmDeleteDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/gradebook/course-based/custom-assignment/confirm-delete-dialog/confirm-delete-dialog.component'
    ).then(m => m.ConfirmDeleteDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '650px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAssignmentCopyDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/course-assignment/form/copy-dialog/assignment-copy-dialog.component').then(
        m => m.AssignmentCopyDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTranscriptDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/class-transcript/dialog/transcript-dialog.component').then(
        m => m.TranscriptDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            height: '100%',
            width: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openConfirmCalculateDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-term-grade/term-grade/confirm-calculate-dialog/confirm-calculate-dialog.component'
    ).then(m => m.ConfirmCalculateDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openRecalculationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-term-grade/term-grade/recalculation-dialog/recalculation-dialog.component'
    ).then(m => m.RecalculationDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTeacherCoursesDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/education/grading-settings-after-due-date/teacher-courses-dialog/teacher-courses-dialog.component'
    ).then(m => m.TeacherCoursesDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1000px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPeriodDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/time-periods/dialog/period-dialog/period-dialog.component').then(
        m => m.PeriodDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '850px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDayInfoDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/academic-calendar/dialog/day-info-dialog.component').then(
        m => m.DayInfoDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openDayMappingDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/academic-calendar/code-mapping/code-mapping-dialog.component').then(
        m => m.CodeMappingDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openQuestionImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/question-bank/questions/import-dialog/question-import-dialog.component').then(
        m => m.QuestionImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openClassesImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/classes/import/classes-import-dialog.component').then(
        m => m.ClassesImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openRoomsImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/rooms/import/rooms-import-dialog.component').then(
        m => m.RoomsImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTeachersImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/timetable/teachers/teachers-import-dialog/teachers-import-dialog.component'
    ).then(m => m.TeachersImportDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCoursesImportDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/courses/import/courses-import-dialog.component').then(
        m => m.CoursesImportDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            minHeight: '100vh',
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPanelDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/panel/dialog/panel-dialog.component').then(
        m => m.PanelDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '660px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openPanelConflictDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/timetable/panel/conflict-dialog/panel-conflict-dialog.component').then(
        m => m.PanelConflictDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openEducationStandardDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/education-standard/dialog/education-standard-dialog.component').then(
        m => m.EducationStandardDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '750px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openTranscriptSettingsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/transcript/transcript-settings/transcript-settings-dialog.component').then(
        m => m.TranscriptSettingsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '600px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openReportCardSendEmailDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/report-card/report-card-generation/send-mail-dialog/send-email-dialog.component'
    ).then(m => m.ReportCardSendEmailDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1030px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openReportCardGenerationDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/report-card/report-card-generation/dialog/report-card-generation-dialog.component'
    ).then(m => m.ReportCardGenerationDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: 'calc(70vw)',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSurveyAnswersDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/survey/list/form/submissions/answers-list/answers-list-dialog/survey-answers-dialog.component'
    ).then(m => m.SurveyAnswersDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openSurveyPreviewDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/survey/list/form/preview-dialog/survey-preview-dialog.component').then(
        m => m.SurveyPreviewDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            width: '100%',
            height: '100%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCertificateTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/certificate-type/dialog/certificate-type-dialog.component').then(
        m => m.CertificateTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1030px',
            height: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openCertifyStudentsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/certify-students/certify-dialog/certify-students-dialog.component').then(
        m => m.CertifyStudentsDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '1030px',
            height: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openIncidentTypeDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/incident/type/dialog/incident-type-dialog.component').then(
        m => m.IncidentTypeDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openActionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/incident/action/dialog/action-dialog.component').then(
        m => m.ActionDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openBehaviorDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/incident/behavior/dialog/behavior-dialog.component').then(
        m => m.BehaviorDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openChangeCourseDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-conflict-report/student/conflict-solve-dialog/change-course-dialog/change-course-dialog.component'
    ).then(m => m.ChangeCourseDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            width: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentCourseConflictSolveDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/course-conflict-report/student/conflict-solve-dialog/conflict-solve-dialog.component'
    ).then(m => m.StudentCourseConflictSolveDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '100vh',
            width: '70%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGroupSelectDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/groups/groups-form/select-dialog/select-dialog.component').then(
        m => m.SelectDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '90%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openGroupSubscriberDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/groups/groups-form/subscriber-dialog/subscriber-dialog.component').then(
        m => m.SubscriberDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '90%',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openUserMessageListDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/user-message/bell/dialog/user-message-list-dialog.component').then(
        m => m.UserMessageListDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openViewAnnouncementDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/layout/components/content/announcement/view-announcement/view-announcement.component'
    ).then(m => m.ViewAnnouncementDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openViewAllAnnouncementsDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/layout/components/content/announcement/view-all/view-all-announcements.component'
    ).then(m => m.ViewAllAnnouncementsDialogComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '800px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openAnnouncementListDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/announcement/bell/dialog/announcement-list-dialog.component').then(
        m => m.AnnouncementListDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            height: '500px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openVisionScreeningDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/vision-list/vision-form/vision-form.component'
    ).then(m => m.VisionFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openHearingScreeningDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/hearing-list/hearing-form/hearing-form.component'
    ).then(m => m.HearingFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openScoliosisScreeningDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/scoliosis-list/scoliosis-form/scoliosis-form.component'
    ).then(m => m.ScoliosisFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openOfficeVisitDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/office-visit-list/office-visit-form/office-visit-form.component'
    ).then(m => m.OfficeVisitFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openStudentMedicalAttachmentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/attachment-list/attachment-form/attachment-form.component'
    ).then(m => m.StudentMedicalAttachmentFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '700px',
            width: '700px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openMedicationPlanDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/medication-plan-list/medication-plan-form/medication-plan-form.component'
    ).then(m => m.MedicationPlanFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openMedicationInventoryActionDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/medication-inventory-action/inventory-action-form/inventory-action-form.component'
    ).then(m => m.InventoryActionFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}
export async function openMedicationLogDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import(
        'app/main/medical-profile-list/medical-profile-form/medication-log-list/medication-log-form/medication-log-form.component'
    ).then(m => m.MedicationLogFormComponent);
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}

export async function openNotifyParentDialog(data: any, closeEvent: any = () => {}) {
    const comp = await import('app/main/daily-attendance/notify-parent-dialog/notify-parent-dialog.component').then(
        m => m.NotifyParentDialogComponent
    );
    const service = environment.injector.get(DialogService);
    service.open(
        comp,
        {
            minWidth: '900px',
            width: '900px',
            data: data,
        },
        response => closeEvent(response)
    );
}
